import React from 'react';

import PageLayout from "../components/layout/PageLayout";
import { MapSection } from '../components/sections';
import Seo from '../components/seo';

const ContactPage = ({ location }) => {
    return (
        <>
            <Seo
                title="Kontakt | P.O.W. Ignaś i Helenka w Kaliskach"
                description="Tel: 25 675 11 37 wew. 12 i 13 całodobowo. Email: pow-ignas@powiatwegrowski.pl Kaliska 50 B, C, 07-130 Łochów."
            />

            <PageLayout location={ location }>
                <MapSection />
            </PageLayout>
        </>
    );
};
 
export default ContactPage;
